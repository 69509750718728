@import '../base/color';

.table {
  width: 100%;
  border:none;
  border-collapse: collapse;
  margin-bottom: 0px;

  tr {
    &.disabled-row {
      background-color: #c4c4c4 !important;

      .disabled-item {
        pointer-events: none !important;
      }
    }

    &:first-child {
      td:first-child { border-top-left-radius: 10px; }
      td:last-child { border-top-right-radius: 10px; }
    }

    &:nth-child(2n):not(.pieza-row) {
      td:first-child { border-bottom-left-radius: 15px; }
      td:last-child { border-bottom-right-radius: 15px; }
    }

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      td:first-child { border-bottom-left-radius: 15px; }
      td:last-child { border-bottom-right-radius: 15px; }
    }
  }

  th, td {
    font-size: 0.75rem;
    border: none !important;
  }

  th {
    padding: 0.5rem 10px;
    font-weight: 400;
    text-transform: uppercase;
  }

  td { 
    background-color: transparent !important;
    padding: 15px 10px;
    font-weight: 400; 

    &:last-child .action { cursor: pointer; }
    
    .action { 
      font-size: 1rem;
      
      &:not(&:last-child) { margin-right: 5px; }
    }
  }

  tbody {
    border: none !important;

    tr.border-light:not(:last-child) { 
      border-bottom: 0.25px solid $fontTertiary !important; 
    }
    tr.border-light:last-child { border-bottom: none !important; }

    .no-border{ border-bottom: none !important; }

   tr {
    border-radius: 10px;
    border-bottom: 12px solid $white;
    background-color: #F5F5F7 !important;

    td { vertical-align: middle; }
    
    td .actions {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      justify-content: center;
      row-gap: 5px;
    }
   }

   thead {
    tr {
      border-bottom: 1px solid $white;
    }
   }
  }

  &-responsive {
    width: 100%;
  }

  .table-tag {
    background-color: $white;
    padding: 6px 12px;
    color: $primary;
    border-radius: 10px;
  }
}